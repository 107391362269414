import React, { useRef, useState, useCallback, useEffect } from "react";
import { Select, Spin } from "antd";
import { debounce } from "lodash";
import { privateRequest } from "../../api-call";

export type Location = {
  id: number;
  city: string;
  state: string;
  country: string;
  latitude: number;
  longitude: number;
  google_place_id: string;
};

export type SearchLocationProps = {
  defaultOptions: Location[];
  selectedValues?: Location[];
  onSelect: (values: Location[]) => void;
  error?: boolean | string;
  wrapperStyle?: React.CSSProperties;
  disabled?: boolean;
  placeholder?: string;
};

const SearchLocation: React.FC<SearchLocationProps> = ({
  defaultOptions,
  selectedValues = [],
  onSelect,
  error,
  wrapperStyle,
  placeholder = "Select locations",
  disabled,
}) => {
  const [options, setOptions] = useState<Location[]>(defaultOptions);
  const [loading, setLoading] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  // Ensure selectedValues are included in options
  useEffect(() => {
    setOptions((prevOptions) => {
      const newOptions = selectedValues.filter(
        (selected) => !prevOptions.some((option) => option.id === selected.id)
      );
      return [...prevOptions, ...newOptions];
    });
  }, [selectedValues]);

  const searchLocalLocations = useCallback(
    (searchQuery: string) => {
      return options.filter((location) =>
        location.city.toLowerCase().includes(searchQuery.toLowerCase())
      );
    },
    [options]
  );

  const fetchLocations = async (searchQuery: string) => {
    setLoading(true);
    try {
      const res = await privateRequest.get("/locations", {
        params: { query: searchQuery },
      });
      if (res.data?.result?.locations) {
        const fetchedLocations: Location[] = res.data.result.locations;

        // Add new locations while avoiding duplicates
        setOptions((prevOptions) => {
          const newLocations = fetchedLocations.filter(
            (newLoc) =>
              !prevOptions.some((existing) => existing.id === newLoc.id)
          );
          return [...prevOptions, ...newLocations];
        });
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetch = useCallback(
    debounce((searchQuery: string) => {
      fetchLocations(searchQuery);
    }, 500),
    []
  );

  const handleSearch = (searchQuery: string) => {
    if (searchQuery.length <= 3) {
      // For short queries, just filter existing options
      const localResults = searchLocalLocations(searchQuery);
      // Don't update options here, let Select's internal filtering work
    } else {
      const localResults = searchLocalLocations(searchQuery);
      if (localResults.length === 0) {
        // Only fetch if we don't have matching results locally
        debouncedFetch(searchQuery);
      }
    }
  };

  const handleChange = (values: number[], option: any) => {
    const selectedLocations = options.filter((loc) => values.includes(loc.id));
    onSelect(selectedLocations);
  };

  return (
    <div ref={selectRef} style={wrapperStyle}>
      <Select
        mode="multiple"
        style={{ width: "100%" }}
        placeholder={placeholder}
        value={selectedValues.map((val) => val.id)}
        onChange={handleChange}
        disabled={disabled}
        filterOption={(input, option) =>
          option?.label
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase()) ?? false
        }
        onSearch={handleSearch}
        loading={loading}
        notFoundContent={
          loading ? <Spin size="small" /> : <>No locations found</>
        }
        options={options.map((location) => ({
          label: location.city,
          value: location.id,
        }))}
      />
      {error ? <p>{error}</p> : <></>}
    </div>
  );
};

export default SearchLocation;
