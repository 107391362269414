import { useMutation } from "@tanstack/react-query";
import { Text } from "../../Components";
import {
  EmailFormWrapper,
  GoogleLoginWrapper,
  LoginWrapper,
  SeperatorText,
} from "./Login.styles";
import * as services from "./Login.services";
import * as types from "./Login.types";
import {
  SignInForm,
  SignUpForm,
  UserExistsForm,
} from "./Login.HelperComponents";
import uploadError from "../../Assets/Images/upload_error.png";
import { useEffect, useRef, useState } from "react";
import { ValueOf } from "../../Utilities/Types";
import * as CONSTANTS from "./Login.constants";
import { AUTH } from "../../Constants";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import Fallback from "../../Components/Fallback";
import { BrandResponse } from "../AboutBusiness/AboutBusiness.types";
import { http } from "../../Utilities";

export default function Login() {
  const navigate = useNavigate();
  const [screenType, setScreenType] = useState<
    ValueOf<typeof CONSTANTS.SCREEN_TYPES>
  >(CONSTANTS.SCREEN_TYPES.USER_EXISTS);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [fallback, setFallback] = useState(false);
  const [signInError, setSignInError] = useState("");

  async function checkOnboardingStageAndNavigate() {
    const endpoint = "/brand";
    const params = {};
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      authentification: true,
    };

    try {
      const response = await http.get<BrandResponse>(endpoint, params, options);
      if (
        response.result.brand.onboarding_stage.toLowerCase() ===
          "New".toLowerCase() ||
        response.result.brand.onboarding_stage.toLowerCase() ===
          "Ongoing".toLowerCase()
      ) {
        navigate("/onboarding/about-business");
      } else {
        navigate("/brand/home/");
      }
    } catch (error) {
      console.error("Error fetching brand onboarding data:", error);
    }
  }

  const userExistsMutation = useMutation({
    mutationFn: services.getEmailStatus,
  });

  const signInMutation = useMutation({
    mutationFn: services.postSignIn,
  });

  const signUpMutation = useMutation({
    mutationFn: services.postSignUp,
  });

  const googleSignInMutation = useMutation({
    mutationFn: services.postGoogleSignIn,
  });

  function handlePostLogin(token: string) {
    localStorage.setItem(AUTH.AUTH_TOKEN_LOCAL_STORAGE_KEY, token);

    checkOnboardingStageAndNavigate();
  }

  async function handleUserStatusFormSubmit(
    userStatusFormData: types.UserStatusFormData
  ) {
    const res = await userExistsMutation.mutateAsync(userStatusFormData.email);
    if (!res.success) {
      setFallback(true);
      return;
    }
    if (!res.result.exists) {
      setScreenType(CONSTANTS.SCREEN_TYPES.SIGN_UP);
      return;
    }
    setScreenType(CONSTANTS.SCREEN_TYPES.SIGN_IN);
  }

  async function handleSignInFormSubmit(signInFormData: types.SignInFormData) {
    const res = await signInMutation.mutateAsync(signInFormData);
    if (!res.success) {
      setFallback(true);

      return;
    }
    if (res.result.verification_status !== "Verified") {
      // Message.error("Sign in failed, check email and password");
      setSignInError("Incorrect password, please try again");
      return;
    }
    if (!res.result.is_email_verified) {
      navigate("/verify-email");
      return;
    }
    Sentry.setUser({
      email: signInFormData.email,
    });
    handlePostLogin(res.result.jwt);
  }

  async function handleSignUpFormSubmit(signUpFormData: types.SignUpFormData) {
    const res = await signUpMutation.mutateAsync(signUpFormData);
    if (!res.success && res.error?.status_code === 500) {
      setFallback(true);
      return;
    }

    navigate("/verify-email");
  }

  async function handleGoogleSignIn({ credential }: any) {
    const res = await googleSignInMutation.mutateAsync(credential);
    if (!res.success) {
      return;
    }
    handlePostLogin(res.result.jwt);
  }

  function getForm() {
    if (screenType === CONSTANTS.SCREEN_TYPES.SIGN_IN) {
      return (
        <SignInForm
          isLoading={signInMutation.isPending}
          onSubmit={handleSignInFormSubmit}
          userData={userExistsMutation.data?.result as types.UserStatusResult}
          handleChange={() => setSignInError("")}
          inputError={signInError}
        />
      );
    }
    if (screenType === CONSTANTS.SCREEN_TYPES.SIGN_UP) {
      return (
        <SignUpForm
          isLoading={signUpMutation.isPending}
          onSubmit={handleSignUpFormSubmit}
          userData={userExistsMutation.data?.result as types.UserStatusResult}
        />
      );
    }
    return (
      <UserExistsForm
        isLoading={userExistsMutation.isPending}
        onSubmit={handleUserStatusFormSubmit}
      />
    );
  }

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  }, []);

  return (
    <LoginWrapper ref={containerRef}>
      {fallback ? (
        <Fallback
          imgSrc={uploadError}
          ctaOnClick={async () => {
            window.location.reload();
          }}
          ctaTitle="Reload"
          loading={false}
          heading="Something is off from our side"
          subText="We’re sorry for the inconvenience, please try again"
        />
      ) : null}
      <Text
        style={{ marginBottom: "6px" }}
        fontWeight="SEMI_BOLD"
        fontSize="XX_LARGE"
        as="h1"
        lineHeight="1"
        textAlign="CENTER"
      >
        Welcome to Ryme
      </Text>
      <Text color="SECONDARY_TEXT" textAlign="CENTER" fontSize="SMALL">
        Join Ryme now to connect with top influencers and elevate your business
      </Text>
      <EmailFormWrapper>{getForm()}</EmailFormWrapper>
      <GoogleLoginWrapper>
        <SeperatorText color="SECONDARY_TEXT" textAlign="CENTER">
          Or
        </SeperatorText>
        <GoogleOAuthProvider clientId={CONSTANTS.GOOGLE_OAUTH_CLIENT_ID}>
          <GoogleLogin
            onSuccess={handleGoogleSignIn}
            width={containerWidth}
            logo_alignment="center"
            size="large"
          />
        </GoogleOAuthProvider>

        {/* <Button
          text="Sign in with Google"
          size="large"
          onClick={login}
          style={{
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "20px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
          icon={<img src={ICONS.Google} alt="Google" />}
        /> */}
      </GoogleLoginWrapper>
    </LoginWrapper>
  );
}
