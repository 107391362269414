import { useInfiniteQuery } from "@tanstack/react-query";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import * as services from "./BrandSubmissions.services";
import * as CONSTANTS from "./BrandSubmissions.constants";
import { ValueOf } from "../../Utilities/Types";
import {
  FullPageContent,
  PageContent,
  PageTitle,
  PageTitleWrapper,
  SubmissionProfileWrapper,
  SubmissionTabs,
} from "./BrandSubmissions.styles";
import Chill from "../../Assets/Images/Chill.svg";
import Card from "../../Assets/Images/Card.svg";

import { useUpdateQueryParams } from "../../Hooks/Router.hooks";
import {
  CompletedSubmissionList,
  OngoingSubmissionList,
  RejectedSubmissionList,
  SubmissionProfileContext,
} from "./BrandSubmissions.HelperComponents";
import { ICONS } from "../../Assets/Icons/Icons";
import { COLORS } from "../../Constants/styles.constants";
import { AppliedTimeWrapper } from "../BrandApplications/BrandApplications.styles";
import { Button, Text } from "../../Components";
import { formatDayMonthYear } from "../../Utilities/DateTime/date-time";
import { useState } from "react";
import uploadError from "../../Assets/Images/upload_error.png";
import Fallback from "../../Components/Fallback";
import FullPageLoader from "../../Components/FullPageLoader/FullPageLoader";

function BrandSubmissions() {
  const { campaignId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const updateQueryParams = useUpdateQueryParams();
  const activeTab = (searchParams.get("tab") ||
    CONSTANTS.TABS.ONGOING) as ValueOf<typeof CONSTANTS.TABS>;

  const [campaignData, setCampaignData] = useState<{
    id: number;
    title: string;
    end_date: string;
  }>(
    {} as {
      id: number;
      title: string;
      end_date: string;
    }
  );
  const [message, setMessage] = useState("");

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [selectItem, setSelectItem] = useState(false);
  const applicationId = parseInt(params.get("application_id") || "");

  const [loading, setLoading] = useState(false);
  const [error5xx, setError5xx] = useState(false);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: [
        CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY,
        campaignId,
        activeTab,
      ],
      queryFn: async ({ pageParam = 0 }) => {
        try {
          setLoading(true); // Start loading as soon as the query function is called
          const response = await services.handleFetchSubmissions(
            campaignId || "",
            CONSTANTS.STATUS_MAP_TABS[activeTab],
            { pageParam }
          );

          if (
            response.response.error &&
            response?.response.error?.status_code >= 404 &&
            response.response.error &&
            response?.response.error?.status_code < 500
          ) {
            throw new Error("Server error");
          }

          if (
            response.response.error &&
            response?.response.error?.status_code >= 500
          ) {
            setError5xx(true);
            setMessage(response.response.message);
            throw new Error("Server error");
          }

          if (
            response.response.result.submissions &&
            response.response.result.submissions.length > 0
          ) {
            updateQueryParams({
              application_id: applicationId
                ? applicationId.toString()
                : response.response.result.submissions[0].application_id.toString(),
            });
          }
          setLoading(false);
          setCampaignData(response.response.result.campaign);

          return {
            data: response,
            nextPage: response.nextPage,
          };
        } catch (error: any) {
          setLoading(false); // Stop loading in case of error
          throw error;
        }
      },
      refetchOnWindowFocus: false,
      initialPageParam: 0,
      getNextPageParam: (lastPage) => {
        return lastPage.data.response.result.submissions &&
          lastPage.data.response.result.submissions.length > 0
          ? lastPage.nextPage
          : null;
      },
    });

  const handleTabChange = (activeKey: string) => {
    setLoading(true);
    updateQueryParams({
      tab: activeKey,
      application_id: "",
      version: "",
    });
  };

  const allSubmissions =
    data?.pages.flatMap((page) =>
      page.data.response.result.submissions
        ? page.data.response.result.submissions
        : []
    ) || [];

  if (error5xx) {
    return (
      <Fallback
        imgSrc={uploadError}
        ctaOnClick={async () => window.location.reload()}
        ctaTitle="Try again"
        loading={false}
        heading="Something is off from our side"
        subText="We're sorry for the inconvenience, please try again"
      />
    );
  }

  return (
    <main>
      <PageTitleWrapper style={{}}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "16px",
            paddingLeft: "16px",
          }}
        >
          <img
            alt="arrow-left"
            src={ICONS.arrowLeft}
            style={{ marginRight: "12px", cursor: "pointer" }}
            onClick={() => navigate("/brand/campaigns")}
          />
          <div>
            <PageTitle fontSize="X_LARGE" fontWeight="SEMI_BOLD">
              Submissions
            </PageTitle>
            {campaignData ? (
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: COLORS.GREY500,
                }}
              >
                {campaignData?.title ? `${campaignData?.title}` : ""}
              </p>
            ) : null}
          </div>
        </div>
        {campaignData?.end_date ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              marginRight: "18px",
            }}
          >
            <AppliedTimeWrapper style={{ marginRight: "12px" }}>
              <Text
                style={{
                  color: COLORS.CONTENT_TERTIARY,
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Campaign end date &nbsp;
              </Text>
              {campaignData ? (
                <Text fontSize="X_SMALL" fontWeight="SEMI_BOLD">
                  {formatDayMonthYear(campaignData?.end_date)}
                </Text>
              ) : null}
            </AppliedTimeWrapper>
            <Button
              text="Campaign details"
              onClick={() => {
                navigate(`/brand/campaign-details/${campaignData?.id}`);
              }}
              style={{ height: "40px" }}
            />
          </div>
        ) : null}
      </PageTitleWrapper>

      {campaignId ? (
        <SubmissionTabs
          onChange={handleTabChange} // Use the updated tab change handler
          tabBarStyle={{
            padding: "0 16px",
            height: "40px",
          }}
          items={[
            {
              key: CONSTANTS.TABS.ONGOING,
              label: "Ongoing",
              children: loading ? (
                <FullPageLoader />
              ) : allSubmissions.length > 0 ? (
                <PageContent>
                  {activeTab === "Ongoing" ? (
                    <OngoingSubmissionList
                      submissions={allSubmissions}
                      handleIntersection={fetchNextPage}
                      hasNextPage={hasNextPage}
                      onSelect={() => setSelectItem(false)}
                      isFetchingNextPage={isFetchingNextPage}
                    />
                  ) : null}
                  {selectItem ? (
                    <div style={{ backgroundColor: COLORS.WHITE }}>
                      <Fallback
                        occupyAvailable
                        loading={false}
                        imgSrc={Chill}
                        ctaOnClick={async () => window.location.reload()}
                        heading="Select a submission from left pane to view details"
                      />
                    </div>
                  ) : (
                    <SubmissionProfileWrapper>
                      {allSubmissions.length > 0 ? (
                        <SubmissionProfileContext
                          onAcceptOrReject={() => {
                            const applicationId =
                              searchParams.get("application_id");

                            localStorage.removeItem(
                              `application-${applicationId}`
                            );
                          }}
                          onReject={() => {
                            setSelectItem(true);
                          }}
                          onVerifyPost={() => {
                            setSelectItem(true);
                          }}
                        />
                      ) : null}
                    </SubmissionProfileWrapper>
                  )}
                </PageContent>
              ) : (
                <Fallback
                  occupyAvailable
                  imgSrc={Card}
                  ctaOnClick={async () => navigate("/brand/campaigns")}
                  ctaTitle="Go back"
                  loading={false}
                  heading={"No submissions found"}
                />
              ),
            },
            {
              key: CONSTANTS.TABS.COMPLETED,
              label: "Completed",
              children: loading ? (
                <FullPageLoader />
              ) : allSubmissions?.length > 0 ? (
                <PageContent>
                  {activeTab === "Completed" ? (
                    <CompletedSubmissionList
                      submissions={allSubmissions}
                      handleIntersection={fetchNextPage}
                      hasNextPage={hasNextPage}
                      isFetchingNextPage={isFetchingNextPage}
                    />
                  ) : null}

                  <SubmissionProfileWrapper>
                    {allSubmissions.length > 0 ? (
                      <SubmissionProfileContext
                        onReject={() => {}}
                        onAcceptOrReject={() => {}}
                        onVerifyPost={() => {}}
                      />
                    ) : null}
                  </SubmissionProfileWrapper>
                </PageContent>
              ) : (
                <Fallback
                  occupyAvailable
                  imgSrc={Card}
                  ctaOnClick={async () => navigate("/brand/campaigns")}
                  ctaTitle="Go back"
                  loading={false}
                  heading={"No submissions found"}
                />
              ),
            },
            {
              key: CONSTANTS.TABS.REJECTED,
              label: "Rejected",
              children: loading ? (
                <FullPageLoader />
              ) : allSubmissions.length > 0 ? (
                <FullPageContent>
                  <RejectedSubmissionList
                    submissions={allSubmissions}
                    handleIntersection={fetchNextPage}
                    hasNextPage={hasNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                  />
                </FullPageContent>
              ) : (
                <Fallback
                  occupyAvailable
                  imgSrc={Card}
                  ctaOnClick={async () => navigate("/brand/campaigns")}
                  ctaTitle="Go back"
                  loading={false}
                  heading={"No submissions found"}
                />
              ),
            },
          ]}
          defaultActiveKey={activeTab || CONSTANTS.SUBMISSION_STATUS.VALIDATED}
        />
      ) : null}
    </main>
  );
}

export default BrandSubmissions;
