import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ICONS } from "../../Assets/Icons/Icons";
import ContainarizedImage from "../../Components/ContainarizedImage/ContainarizedImage";
import { COLORS } from "../../Constants/styles.constants";
import React, { useEffect, useRef, useState } from "react";
import * as types from "./BrandSubmissions.types";
import { dateTime } from "../../Utilities";
import Hls from "hls.js";
import {
  SubmissionHeader,
  SubmitActionButtons,
} from "./BrandSubmissions.HelperComponents";
import * as utilities from "./BrandSubmissions.utilities";
import SuccessIllustration from "../../Assets/Images/success.png";

import styled from "styled-components";
import {
  Button,
  Checkbox,
  Form,
  FormItem,
  Input,
  Modal,
  Select,
  Tag,
  Text,
} from "../../Components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as services from "./BrandSubmissions.services";
import * as CONSTANTS from "./BrandSubmissions.constants";
import { ApplicantProfileContext } from "../BrandApplications/BrandApplications.HelperComponents";
import { useOutsideClick } from "../../Hooks/Router.hooks";
import FullPageLoader from "../../Components/FullPageLoader/FullPageLoader";
import { message } from "antd";
import {
  ApproveLiveActionsWrapper,
  CheckboxContentWrapper,
  WrongLinkHintText,
} from "./BrandSubmissions.styles";
import { BaseOptionType } from "antd/es/select";
import { DefaultOptionType } from "antd/es/cascader";
import { CheckboxChangeEvent } from "antd/es/checkbox";

export const SubmissionVideoAndDetails: React.FC<types.SubmissionMainProps> = ({
  feedback,
  submission,
  versions,
  latestVersion,
  partner,
}) => {
  const videoRef = useRef<null | HTMLVideoElement>(null);
  const [searchParams] = useSearchParams();
  const applicationId = Number(searchParams.get("application_id") || 0);
  const [showInfluencerDetails, setShowInfluencerDetails] = useState(false);

  const version = parseInt(searchParams.get("version") || `${latestVersion}`);

  const hasGivenFeedback = feedback?.comments?.length;

  useEffect(() => {
    const hls = new Hls({
      debug: true,
    });

    if (Hls.isSupported() && videoRef.current) {
      hls.loadSource(submission.content.url);
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.ERROR, (err) => {
        console.log(err);
      });
    } else {
      console.log("load");
    }
    return () => {
      // cleanup (when component destroyed or when useEffect runs twice on StrictMode)
      hls.destroy();
    };
  }, [submission.content.url]);

  if (!applicationId) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: COLORS.WHITE,
        borderRadius: "8px",
        padding: "12px 18px",
      }}
    >
      <ApplicantProfileContext
        type="submission"
        modal
        showModal={showInfluencerDetails}
        application_id_prop={applicationId}
        onClose={() => setShowInfluencerDetails(false)}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "48px",
          marginBottom: "12px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {versions ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <SubmissionHeader
                versions={versions}
                latestVersion={latestVersion}
              />
            </div>
          ) : (
            <p
              style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
              }}
            >
              Submission {latestVersion}
            </p>
          )}

          {submission.brand_visible_status ? (
            <Tag color={CONSTANTS.COLOR_MAP[submission.status]}>
              {submission.brand_visible_status}
            </Tag>
          ) : null}
        </div>
        <InlfuencerDetailButton
          setShowInfluencerDetails={() => {
            setShowInfluencerDetails(true);
          }}
          name={partner.name}
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "5.5fr 4.5fr",
          gap: "12px",
          height: "calc(100vh - 50px - 70px - 50px - 56px - 70px)",
        }}
      >
        {/* LEFT */}
        <div
          style={{
            flex: 6,
            backgroundColor: COLORS.GREY50,
            borderRadius: "8px",
            gap: "12px",
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <video
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain", // Adjust this to "cover" if needed
              backgroundColor: "#000",
            }}
            controls
            ref={videoRef}
          />
        </div>
        {/* RIGHT */}
        {hasGivenFeedback ? (
          <FeedbacksSection
            feedback={feedback}
            application_id={submission.application_id}
            submission_id={submission.id}
            latestVersion={version === latestVersion}
            onTimeStampPress={(timestamp: number) => {
              if (videoRef.current) {
                videoRef.current.currentTime = timestamp;
                videoRef.current.play();
                videoRef.current.focus();
              }
            }}
          />
        ) : (
          <div
            style={{
              flex: 4,
              backgroundColor: COLORS.GREY50,
              height: "100%",
              width: "100%",
              borderRadius: "8px",
            }}
          >
            <SubmissionDetails submission={submission} />
          </div>
        )}
      </div>
    </div>
  );
};

export const InlfuencerDetailButton = ({
  setShowInfluencerDetails,
  name,
}: {
  setShowInfluencerDetails: () => void;
  name: string;
}) => {
  return (
    <button
      onClick={setShowInfluencerDetails}
      style={{
        background: "none",
        border: 0,
        display: "flex",
        alignItems: "center",
        gap: "10px",
        color: COLORS.PRIMARY,
        cursor: "pointer",
      }}
    >
      <ContainarizedImage
        src={ICONS.userPrimary}
        alt="user"
        width="16px"
        height="16px"
      />
      <p style={{ fontSize: "12px", fontWeight: "600" }}>{name}</p>
    </button>
  );
};

export const SubmissionPosted: React.FC<types.SubmissionPostedProps> = ({
  submission,
  partner,
  onVerifyPost,
}) => {
  const videoRef = useRef<null | HTMLVideoElement>(null);
  const [searchParams] = useSearchParams();
  const applicationId = Number(searchParams.get("application_id") || 0);
  const [showWrongReasonModal, setShowWrongReasonModal] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [isChecked, setIsChecked] = useState(false);
  const queryClient = useQueryClient();
  const [showInfluencerDetails, setShowInfluencerDetails] = useState(false);

  useEffect(() => {
    const hls = new Hls({
      debug: true,
    });

    if (Hls.isSupported() && videoRef.current) {
      hls.loadSource(submission.content.url);
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.ERROR, (err) => {
        console.log(err);
      });
    } else {
      console.log("load");
    }
    return () => {
      // cleanup (when component destroyed or when useEffect runs twice on StrictMode)
      hls.destroy();
    };
  }, [submission.content.url]);

  function handleCheckboxChange(e: CheckboxChangeEvent) {
    setIsChecked(e.target.checked);
  }

  const {
    mutateAsync: updateSubmissionAsWrongPost,
    isPending: isWrongPostPending,
  } = useMutation({
    mutationFn: services.updateSubmissionAsWrongPost,
    onSuccess: () => {
      setShowWrongReasonModal(false);
      setSelectedReasons([]);
      queryClient.invalidateQueries({
        queryKey: [CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY],
      });
    },
  });

  const {
    mutateAsync: updateSubmissionStatus,
    isPending: isStatusUpdatePending,
  } = useMutation({
    mutationFn: services.updateSubmissionStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY],
      });
      onVerifyPost();
      message.success('Submission moved to "Completed"');
    },
  });

  const { data } = useQuery({
    queryKey: ["applicationMeta"],
    queryFn: () => services.getApplicationMeta(),
    select: utilities.transformApplicationMeta,
  });

  function handleVerifyPost({ approveConsent }: { approveConsent: boolean }) {
    if (!approveConsent) {
      return;
    }
    updateSubmissionStatus({
      submissionId: submission.id,
      status: CONSTANTS.SUBMISSION_STATUS.POST_VERIFIED,
    });
  }

  function handleWrongPostSubmit(formData: {
    wrongReason: string[];
    comment?: string;
  }) {
    updateSubmissionAsWrongPost({
      submissionId: submission.id,
      reasons: formData.wrongReason,
      comment: formData.comment,
    });
  }

  function handleReasonChange(
    value: unknown,
    option:
      | BaseOptionType
      | DefaultOptionType
      | (BaseOptionType | DefaultOptionType)[]
  ) {
    setSelectedReasons(value as string[]);
  }

  function handleModalClose() {
    setShowWrongReasonModal(false);
    setSelectedReasons([]);
  }

  if (!applicationId) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: COLORS.WHITE,
        borderRadius: "8px",
        padding: "12px 18px",
      }}
    >
      <ApplicantProfileContext
        type="submission"
        modal
        showModal={showInfluencerDetails}
        application_id_prop={applicationId}
        onClose={() => setShowInfluencerDetails(false)}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "48px",
          marginBottom: "12px",
          gap: "8px",
        }}
      >
        <p style={{ fontWeight: "600", fontSize: "16px", lineHeight: "24px" }}>
          Verify the post
        </p>
        {submission.brand_visible_status ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            <Tag color={CONSTANTS.COLOR_MAP[submission.status]}>
              {submission.brand_visible_status}
            </Tag>
            <InlfuencerDetailButton
              setShowInfluencerDetails={() => {
                setShowInfluencerDetails(true);
              }}
              name={partner.name}
            />
          </div>
        ) : null}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "5fr 5fr",
          gap: "12px",
          height: "calc(100vh - 50px - 70px - 50px - 70px)",
        }}
      >
        {/* LEFT */}
        <div
          style={{
            flex: 6,
            backgroundColor: COLORS.GREY50,
            borderRadius: "8px",
            gap: "12px",
            height: "100%",
            width: "100%",
            padding: "12px",
            overflow: "hidden",
          }}
        >
          <div>
            <p style={{ fontWeight: "700", marginBottom: "12px" }}>
              Live on Instagram
            </p>
          </div>
          <video
            style={{
              width: "100%",
              height: "calc(100% - 88px)",
              objectFit: "contain",
              backgroundColor: "#000",
              borderRadius: "4px",
            }}
            src={submission.content.url}
            controls
            ref={videoRef}
          />
          <div
            style={{
              padding: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              text="Check post on instagram"
              iconPosition="end"
              type="primary"
              style={{ height: "40px", display: "flex", alignItems: "center" }}
              icon={
                <ContainarizedImage
                  src={ICONS.link}
                  height="20px"
                  width="20px"
                  alt="link"
                />
              }
              onClick={() => {
                window.open(submission.post_url, "_target");
              }}
            />
          </div>
        </div>
        {/* RIGHT */}
        <div
          style={{
            backgroundColor: COLORS.GREY50,
            height: "100%",
            width: "100%",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "left",
            padding: "24px",
          }}
        >
          <p
            style={{
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "32px",
              marginBottom: "4px",
            }}
          >
            Verify the post to start getting live insights
          </p>
          <p
            style={{
              lineHeight: "22px",
              marginBottom: "18px",
              color: COLORS.GREY500,
            }}
          >
            Please note that once the post is verified, no more changes could be
            requested from the influencer. Also, it means an approval from your
            end for paying out to the influencer.
          </p>
          <Form onSubmit={handleVerifyPost}>
            <CheckboxContentWrapper>
              <FormItem
                name="approveConsent"
                rules={[
                  {
                    required: true,
                    message: "Please acknowledge the live content",
                  },
                ]}
                valuePropName="checked"
              >
                <Checkbox onChange={handleCheckboxChange}>
                  I acknowledge that the live post matches the submission we
                  approved.
                </Checkbox>
              </FormItem>
            </CheckboxContentWrapper>
            <ApproveLiveActionsWrapper>
              <Button
                text="Wrong link/post?"
                onClick={() => setShowWrongReasonModal(true)}
                style={{ height: "40px" }}
              />
              <Button
                text="Verify Post"
                htmlType="submit"
                type="primary"
                loading={isStatusUpdatePending}
                style={{ height: "40px" }}
                disabled={!isChecked}
              />
            </ApproveLiveActionsWrapper>
          </Form>
        </div>
      </div>
      <Modal
        title="Wrong link/post?"
        open={showWrongReasonModal}
        onCancel={handleModalClose}
        footer={null}
      >
        <div>
          <WrongLinkHintText color="SECONDARY_TEXT" fontSize="SMALL">
            If the link is incorrect or you need edits to the live post, you can
            submit a reason here to request the necessary changes.
          </WrongLinkHintText>
          <Form onSubmit={handleWrongPostSubmit}>
            <FormItem
              name="wrongReason"
              label="Select reason(s)"
              rules={[
                {
                  required: true,
                  message: "Please add a reason",
                },
              ]}
            >
              <Select
                options={data?.wrongPostReasons || []}
                placeholder="Select reason(s)"
                mode="multiple"
                onChange={handleReasonChange}
              />
            </FormItem>
            <FormItem name="comment" label="Additional Comments">
              <Input
                type="textarea"
                placeholder="Add any additional comments or details here (optional)"
              />
            </FormItem>
            <SubmitActionButtons
              okayText="Request Changes"
              closeModal={handleModalClose}
              isPending={isWrongPostPending}
              okayHtmlType="submit"
              disabled={selectedReasons.length === 0}
            />
          </Form>
        </div>
      </Modal>
    </div>
  );
};

const renderMessageWithLinks = (message: string) => {
  // Regular expression for URLs
  const REG_EXPS = {
    urlPattern: /(https?:\/\/[^\s]+)/g, // Example pattern for URLs
  };

  // Split the message into parts based on the URL pattern
  const parts = message.split(REG_EXPS.urlPattern);

  return (
    <>
      {parts.map((part, index) => {
        const isLink = REG_EXPS.urlPattern.test(part);

        if (isLink) {
          return (
            <span
              key={index}
              onClick={() => window.open(part, "_blank")}
              style={{
                fontSize: "12px",
                color: COLORS.BLUE400,
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {part}
            </span>
          );
        }

        return (
          <span key={index} style={{ fontSize: "12px" }}>
            {part}
          </span>
        );
      })}
    </>
  );
};

const FeedbacksSection: React.FC<types.FeedbacksSectionProps> = ({
  feedback,
  application_id,
  submission_id,
  latestVersion,
  onTimeStampPress,
}) => {
  return (
    <div
      style={{
        height: "100%",
        minHeight: 0,
        backgroundColor: COLORS.PRIMARY_LIGHT_BACKGROUND_COLOR,
        border: `1px solid ${COLORS.GREY200}`,
        borderRadius: "6px",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          padding: "12px",
          borderBottom: `1px solid ${COLORS.GREY200}`,
          height: "70px",
          backgroundColor: COLORS.WHITE,
        }}
      >
        <p
          style={{
            color: "#000",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "600",
          }}
        >
          Feedback
        </p>
        <p
          style={{
            color: COLORS.GREY500,
            fontSize: "12px",
            lineHeight: "16p",
            fontWeight: "500",
          }}
        >
          View comments and respond to queries
        </p>
      </div>

      <CommentReplyFeedback
        feedback={feedback}
        application_id={application_id}
        submission_id={submission_id}
        latestVersion={latestVersion}
        onTimeStampPress={onTimeStampPress}
      />
    </div>
  );
};

const CommentReplyFeedback: React.FC<types.CommentReplyFeedbackProps> = ({
  feedback,
  application_id,
  latestVersion,
  submission_id,
  onTimeStampPress,
}) => {
  const comments = feedback.comments || [];
  const [replyIndex, setReplyIndex] = useState<null | number>(null);
  const [replyText, setReplyText] = useState("");
  const queryClient = useQueryClient();
  const [showMore, setShowMore] = useState(false);

  const { mutateAsync: postCreateReply, isPending } = useMutation({
    mutationFn: services.postCreateReply,
    onSuccess: () => {
      setReplyIndex(null);
      queryClient.invalidateQueries({
        queryKey: [
          CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY,
          CONSTANTS.CAMPAIGN_SUBMISSION_PROFILE_QUERY_KEY,
          application_id,
        ],
      });
    },
  });

  function onReplySubmit() {
    setReplyIndex(null);
    setReplyText("");
    if (replyIndex !== null && replyIndex >= 0) {
      postCreateReply({
        submission_id,
        text: replyText,
        feedback_id: feedback.id,
        parent_comment_id: comments[replyIndex].parent_comment.id,
      });
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 70px)",
        minHeight: 0,
      }}
    >
      <div
        style={{
          overflow: "auto",
          padding: "8px 12px",
          flex: 1,
        }}
      >
        {comments.length === 0 ? (
          <div>No comments added yet.</div>
        ) : (
          comments.map((comment, index) => (
            <div
              style={{
                backgroundColor: COLORS.WHITE,
                border: `1px solid ${COLORS.GREY50}`,
                borderRadius: "6px",
                marginBottom: "11px",
                padding: "12px",
              }}
            >
              <div
                style={{
                  backgroundColor: COLORS.WHITE,
                  display: "flex",
                  gap: "8px",
                }}
                key={index}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <ContainarizedImage
                    height="32px"
                    width="32px"
                    contStyle={{
                      borderRadius: "100%",
                      flexShrink: 0,
                      backgroundColor: COLORS.GREY500,
                      overflow: "hidden",
                    }}
                    style={{
                      objectFit: "contain",
                    }}
                    alt="profile"
                    src={`${
                      comment.parent_comment.profile_picture
                    }?a=${new Date()}`}
                  />
                  {/* {comment.replies ? (
                    <div
                      style={{
                        width: "1px",
                        height: "100%",
                        backgroundColor: COLORS.BORDER_COLOR,
                      }}
                    ></div>
                  ) : null} */}
                </div>

                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "12px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "20px",
                        }}
                      >
                        {comment.parent_comment.name}
                      </p>
                      <p
                        style={{
                          backgroundColor: COLORS.PURPLE100,
                          padding: "4px",
                          color: COLORS.PRIMARY,
                          fontSize: "12px",
                          fontWeight: "600",
                          lineHeight: "16px",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          onTimeStampPress(
                            comment.parent_comment.video_timestamp
                          )
                        }
                      >
                        {dateTime.dateTime.formatSecondsToTime(
                          comment.parent_comment.video_timestamp
                        )}
                      </p>
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          lineHeight: "16px",
                          color: COLORS.GREY400,
                        }}
                      >
                        {timeAgo(comment.parent_comment.created_at)}
                      </p>
                    </div>
                  </div>
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "16px",
                      color: COLORS.GREY500,
                      wordBreak: "break-word",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                      display: "block",
                      maxWidth: "100%",
                      wordWrap: "break-word",
                      marginBottom: comment.replies ? "24px" : "0px",
                    }}
                  >
                    {renderMessageWithLinks(comment.parent_comment.text)}
                  </p>
                </div>
              </div>
              {/* FIRST COMMENT */}
              <div>
                {comment.replies && comment.replies.length > 1 ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      marginLeft: "30px",
                    }}
                    key={0}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "32px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <ContainarizedImage
                          contStyle={{ cursor: "pointer" }}
                          src={
                            showMore ? ICONS.angleUpDown : ICONS.sortVertical
                          }
                          onClick={() => setShowMore(!showMore)}
                          alt="control"
                        />
                        <div
                          style={{
                            width: "1px",
                            height: "20px",
                            backgroundColor: "",
                          }}
                        ></div>
                      </div>

                      {comment.replies && comment.replies?.length - 1 !== 0 ? (
                        <div
                          style={{
                            width: "1px",
                            height: "100%",
                            backgroundColor: COLORS.BORDER_COLOR,
                          }}
                        ></div>
                      ) : null}
                    </div>

                    <div
                      style={{ width: "100%", cursor: "pointer" }}
                      onClick={() => setShowMore(!showMore)}
                    >
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "600",
                          lineHeight: "16px",
                          marginTop: "3px",
                          color: COLORS.PRIMARY,
                        }}
                      >
                        {showMore
                          ? "Hide replies"
                          : `Show all replies (${comment.replies.length - 1})`}
                      </p>
                    </div>
                  </div>
                ) : null}

                {comment.replies &&
                  (() => {
                    let filteredReplies = comment.replies;

                    if (comment.replies.length > 1 && !showMore) {
                      filteredReplies = comment.replies.slice(-1);
                    }

                    return filteredReplies.map((reply, replyCommentIndex) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            gap: "8px",
                            marginLeft: "40px",
                          }}
                          key={1 + replyCommentIndex}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <ContainarizedImage
                              height="32px"
                              width="32px"
                              contStyle={{
                                borderRadius: "100%",
                                flexShrink: 0,
                                backgroundColor: COLORS.GREY500,
                                overflow: "hidden",
                              }}
                              src={`${reply.profile_picture}?a=${new Date()}`}
                              alt="profile"
                            />
                            {comment.replies &&
                            filteredReplies?.length - 1 !==
                              replyCommentIndex ? (
                              <div
                                style={{
                                  width: "1px",
                                  height: "100%",
                                  backgroundColor: COLORS.BORDER_COLOR,
                                }}
                              ></div>
                            ) : null}
                          </div>

                          <div style={{ width: "100%" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "12px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "12px",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    lineHeight: "20px",
                                  }}
                                >
                                  {reply.name}
                                </p>

                                <p
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    lineHeight: "16px",
                                    color: COLORS.GREY400,
                                  }}
                                >
                                  {timeAgo(reply.created_at)}
                                </p>
                              </div>
                            </div>
                            <p
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                lineHeight: "16px",
                                color: COLORS.GREY500,
                                wordBreak: "break-word",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "normal",
                                display: "block",
                                maxWidth: "100%",
                                wordWrap: "break-word",
                                marginBottom: "24px",
                              }}
                            >
                              {renderMessageWithLinks(reply.text)}
                            </p>
                          </div>
                        </div>
                      );
                    });
                  })()}
              </div>

              {latestVersion ? (
                <div style={{ display: "flex", gap: "8px" }}>
                  {comment.replies ? (
                    <div
                      style={{
                        height: "32px",
                        width: "32px",
                        borderRadius: "100%",
                        flexShrink: 0,
                      }}
                    />
                  ) : null}

                  {replyIndex === index ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifySelf: "flex-end",
                        boxShadow: "4px 4px 30px 0px #0000001a",
                        borderRadius: "4px",
                        overflow: "hidden",
                        flex: 1,
                      }}
                    >
                      <StyledCommentEditInput
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                        }}
                        autoFocus
                        value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                        placeholder="Add your reply here"
                      />
                      <div
                        style={{
                          backgroundColor: COLORS.WHITE,
                          padding: "8px 12px",
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "12px",
                        }}
                      >
                        <Button
                          text="Cancel"
                          onClick={() => {
                            setReplyIndex(null);
                          }}
                          style={{ minWidth: "100px", height: "40px" }}
                        />
                        <Button
                          type="primary"
                          text="Post"
                          loading={isPending}
                          onClick={onReplySubmit}
                          style={{ minWidth: "100px", height: "40px" }}
                        />
                      </div>
                    </div>
                  ) : comment.replies ? (
                    <Button
                      text="Reply"
                      onClick={() => {
                        setReplyIndex(index);
                      }}
                      iconPosition="start"
                      icon={
                        <ContainarizedImage
                          src={ICONS.arrowReply}
                          height="16px"
                          width="16px"
                          alt="reply"
                        />
                      }
                      style={{
                        height: "36px",
                        minWidth: "80px",
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "600",
                      }}
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

const SubmissionDetails: React.FC<types.SubmissionContentViewProps> = ({
  submission,
}) => {
  const normalizedCaption = submission.caption.replace(/\/n/g, "\n");
  return (
    <div style={{ padding: "12px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginBottom: "12px",
        }}
      >
        <ContainarizedImage
          src={submission.content.thumbnail}
          height="44px"
          width="44px"
          contStyle={{
            borderRadius: "4px",
            backgroundColor: COLORS.GREY500,
            overflow: "hidden",
          }}
          alt="thumbnail"
        />
        <div style={{ padding: "5px 0" }}>
          <p style={{ fontSize: "14px", fontWeight: "600", lineHeight: "20p" }}>
            {submission.content.title}
          </p>
          <p
            style={{
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "16px",
              color: COLORS.GREY500,
            }}
          >
            Uploaded at:{" "}
            {dateTime.dateTime.formatDateTime(submission.updated_at)}
          </p>
        </div>
      </div>
      <div>
        <div style={{ marginBottom: "24px" }}>
          <p
            style={{ fontSize: "14px", fontWeight: "600", lineHeight: "20px" }}
          >
            Caption
          </p>
          <p
            style={{
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "16px",
              color: COLORS.GREY500,
            }}
          >
            {normalizedCaption.split("\n").map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  {item}
                  <br />
                </React.Fragment>
              );
            })}
          </p>
        </div>

        <p style={{ fontSize: "14px", fontWeight: "600", lineHeight: "20px" }}>
          Hashtags
        </p>
        <p
          style={{
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "16px",
            color: COLORS.GREY500,
          }}
        >
          {submission.hashtags.join(", ")}
        </p>
      </div>
    </div>
  );
};

export const RequestChanges: React.FC<types.RequestChangesProps> = ({
  submission,
  closeModal,
}) => {
  const videoRef = useRef<null | HTMLVideoElement>(null);
  const [videoCommentTimestamp, setVideoCommentTimestamp] = useState(0);
  const queryClient = useQueryClient();
  const [comments, setComments] = useState<Comment[]>([]);
  const [confirmSubmit, setConfirmSubmit] = useState(false);

  useEffect(() => {
    const hls = new Hls({
      debug: true,
    });

    if (Hls.isSupported() && videoRef.current) {
      hls.loadSource(submission.content.url);
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.ERROR, (err) => {
        console.log(err);
      });
    } else {
      console.log("load");
    }
    return () => {
      // cleanup (when component destroyed or when useEffect runs twice on StrictMode)
      hls.destroy();
    };
  }, [submission.content.url]);

  const {
    mutateAsync: updateSubmissionStatus,
    isPending: isStatusUpdatePending,
  } = useMutation({
    mutationFn: services.updateSubmissionStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY],
      });
      window.location.reload();
      message.success("Feedback submitted");
    },
  });

  const { mutateAsync: postCreateFeedback, isPending } = useMutation({
    mutationFn: services.postCreateFeedback,
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [CONSTANTS.CAMPAIGN_SUBMISSION_QUERY_KEY],
      });
      localStorage.removeItem(`application-${submission.application_id}`);
      await updateSubmissionStatus({
        submissionId: submission.id,
        status: CONSTANTS.SUBMISSION_STATUS.CHANGE_REQUESTED,
      });
      closeModal();
    },
  });

  function handleFeedbackFocus() {
    if (!videoRef.current) {
      return;
    }
    videoRef.current.pause();
    setVideoCommentTimestamp(Math.floor(videoRef.current.currentTime));
  }

  function onTimeUpdate(event: React.SyntheticEvent<HTMLVideoElement, Event>) {
    // if the video is playing, we don't want to update the timestamp
    if (!videoCommentTimestamp) {
      return;
    }
    setVideoCommentTimestamp(Math.floor(event.currentTarget.currentTime));
  }

  function handleVideoPause() {
    setTimeout(() => {
      if (!videoRef.current || !videoRef.current.paused) {
        return;
      }
      setVideoCommentTimestamp(Math.floor(videoRef.current.currentTime));
    }, 100);
  }

  return (
    <div
      style={{
        width: "100%",
        height: "85vh",
        minHeight: 0,
      }}
    >
      {isStatusUpdatePending ? (
        <FullPageLoader />
      ) : (
        <>
          <Modal
            title="Confirm Feedback Submission"
            open={confirmSubmit}
            onCancel={() => setConfirmSubmit(false)}
            footer={null}
            centered
          >
            <div>
              <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
                Please note: Once you submit the feedback, you will not be able
                to request any further changes or add additional comments for
                this version. Are you sure you want to proceed with submitting
                your feedback?
              </Text>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                <Button
                  text="Back to Feedback"
                  onClick={() => {
                    setConfirmSubmit(false);
                  }}
                  style={{ marginRight: "10px" }}
                />
                <Button
                  text="Submit Feedback"
                  onClick={() => {
                    const storedComments = localStorage.getItem(
                      `application-${submission.application_id}`
                    );
                    let updatedComments: Feedback[] | undefined;
                    if (storedComments) {
                      // Parse the stored comments into an array
                      const commentsArray = JSON.parse(storedComments);

                      // Remove the `created_at` property from each comment
                      updatedComments = commentsArray.map(
                        (comment: Comment) => {
                          const { created_at, ...rest } =
                            comment.parent_comment; // Destructure and exclude `created_at`
                          return {
                            ...comment,
                            parent_comment: rest, // Reconstruct without `created_at`
                          };
                        }
                      );
                    }
                    if (updatedComments) {
                      postCreateFeedback({
                        submissionId: submission.id,
                        feedbacks: updatedComments,
                      });
                    }
                  }}
                  type="primary"
                />
              </div>
            </div>
          </Modal>
          <div
            style={{
              height: "36px",
              padding: "12px 18px",
              paddingBottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
              }}
            >
              Add your feedback on Submission-{submission.version}
            </p>
            <ContainarizedImage
              alt="close"
              src={ICONS.close}
              onClick={() => {
                closeModal();
              }}
              height="20px"
              width="20px"
              contStyle={{ cursor: "pointer" }}
            />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "5.5fr 4.5fr",
              gap: "12px",
              height: "calc(90% - 36px)",
              padding: "12px 18px",
              minHeight: "0px",
            }}
          >
            {/* LEFT */}
            <div
              style={{
                flex: 6,
                backgroundColor: COLORS.GREY50,
                borderRadius: "8px",
                gap: "12px",
                height: "100%",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <video
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain", // Adjust this to "cover" if needed
                  backgroundColor: "#000",
                }}
                src={submission.content.url}
                controls
                ref={videoRef}
                onPlay={() => setVideoCommentTimestamp(0)}
                onTimeUpdate={onTimeUpdate}
                onPause={handleVideoPause}
              />
            </div>
            {/* RIGHT */}
            <div
              style={{
                flex: 4,
                backgroundColor: COLORS.PRIMARY_LIGHT_BACKGROUND_COLOR,
                height: "100%",
                width: "100%",
                borderRadius: "8px",
                border: `1px solid ${COLORS.GREY200}`,
                minHeight: "0px",
                overflow: "hidden",
              }}
            >
              <AddFeedbackPoints
                handleFeedbackFocus={handleFeedbackFocus}
                videoCommentTimestamp={videoCommentTimestamp}
                comments={comments}
                onTimeStampPress={(timestamp: number) => {
                  if (videoRef.current) {
                    videoRef.current.currentTime = timestamp;
                    videoRef.current.play();
                    videoRef.current.focus();
                  }
                }}
                setComments={setComments}
              />
            </div>
          </div>
          <div
            style={{
              padding: "0 18px",
              backgroundColor: COLORS.WHITE,
              height: "10%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderTop: `1px solid ${COLORS.GREY200}`,
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <ContainarizedImage
                src={ICONS.info}
                height="14px"
                width="14px"
                alt="icon"
              />
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: COLORS.GREY500,
                  lineHeight: "20px",
                }}
              >
                Please watch the entire video to submit the feedback. We aim at
                reducing revision cycles.{" "}
              </p>
            </div>

            <SubmitActionButtons
              okayText="Submit Feedback"
              onOkay={() => {
                setConfirmSubmit(true);
              }}
              closeModal={closeModal}
              isPending={isPending}
              buttonHeight="40px"
              disabled={!Boolean(comments.length)}
            />
          </div>
        </>
      )}
    </div>
  );
};

const AddFeedbackPoints: React.FC<{
  handleFeedbackFocus: React.FocusEventHandler<any>;
  videoCommentTimestamp: number;
  comments: Comment[];
  setComments: (comments: Comment[]) => void;
  onTimeStampPress: (timestamp: number) => void;
}> = ({
  handleFeedbackFocus,
  videoCommentTimestamp,
  comments,
  setComments,
  onTimeStampPress,
}) => {
  return (
    <div style={{ height: "100%", minHeight: 0 }}>
      <div
        style={{
          padding: "12px",
          backgroundColor: COLORS.WHITE,
          borderBottom: `1px solid ${COLORS.GREY200}`,
          height: "70px",
        }}
      >
        <p
          style={{
            color: "#000",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "600",
          }}
        >
          Add comments on the video timeline
        </p>
        <p
          style={{
            color: COLORS.GREY500,
            fontSize: "12px",
            lineHeight: "16p",
            fontWeight: "500",
          }}
        >
          Add all comments at once to minimize revision cycles
        </p>
      </div>
      <CommentInput
        handleFeedbackFocus={handleFeedbackFocus}
        videoCommentTimestamp={videoCommentTimestamp}
        comments={comments}
        setComments={setComments}
        onTimeStampPress={onTimeStampPress}
      />
    </div>
  );
};

type Comment = {
  parent_comment: {
    text: string;
    created_at: string;
    video_timestamp: number;
  };
};

type Feedback = {
  parent_comment: {
    text: string;
    video_timestamp: number;
  };
};

export function timeAgo(isoDateString: string): string {
  const past = new Date(isoDateString);
  const now = new Date();

  const diffMs = now.getTime() - past.getTime();
  const diffSecs = Math.floor(diffMs / 1000);
  const diffMins = Math.floor(diffSecs / 60);
  const diffHours = Math.floor(diffMins / 60);
  const diffDays = Math.floor(diffHours / 24);

  if (diffDays > 0) {
    return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
  } else if (diffHours > 0) {
    return `${diffHours} hour${diffHours > 1 ? "s" : ""} ago`;
  } else if (diffMins > 0) {
    return `${diffMins} minute${diffMins > 1 ? "s" : ""} ago`;
  } else if (diffSecs > 0) {
    return `${diffSecs} second${diffSecs > 1 ? "s" : ""} ago`;
  } else {
    return "just now";
  }
}

const FloatingOptions: React.FC<{
  onDelete: () => void;
  onEdit: () => void;
}> = ({ onDelete, onEdit }) => {
  const optionRef = useRef<HTMLDivElement>(null);

  useOutsideClick(optionRef, () => setIsVisible(false));

  const [isVisible, setIsVisible] = useState<boolean>(false);

  // Toggle the visibility of the floating container
  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div style={{ position: "relative" }}>
      <ContainarizedImage
        src={ICONS.threeDot}
        height="20px"
        width="20px"
        contStyle={{ cursor: "pointer" }}
        onClick={handleClick}
        alt="three-dot"
      />

      {isVisible && (
        <div
          ref={optionRef}
          style={{
            position: "absolute",
            top: "100%", // Place it below the ContainerizedImage
            right: "0",
            backgroundColor: "white",
            border: `1px solid ${COLORS.SECONDARY_BACKGROUND_COLOR}`,
            borderRadius: "8px",
            boxShadow: "4px 4px 30px 0px #0000001A",
            width: "120px", // Set the width for the floating container
            zIndex: 10,
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "600",
              cursor: "pointer",
              margin: "0",
              padding: "8px 12px",
              borderBottom: `1px solid ${COLORS.GREY100}`,
            }}
            onClick={() => {
              onEdit();
              setIsVisible(false); // Close the floating container
            }}
          >
            Edit
          </p>
          <p
            style={{
              fontSize: "12px",
              fontWeight: "600",
              cursor: "pointer",
              margin: "0",
              padding: "8px 12px",
            }}
            onClick={() => {
              onDelete();
              setIsVisible(false); // Close the floating container
            }}
          >
            Delete
          </p>
        </div>
      )}
    </div>
  );
};

const CommentInput: React.FC<{
  handleFeedbackFocus: React.FocusEventHandler<any>;
  videoCommentTimestamp: number;
  comments: Comment[];
  setComments: (comments: Comment[]) => void;
  onTimeStampPress: (timestamp: number) => void;
}> = ({
  handleFeedbackFocus,
  videoCommentTimestamp,
  comments,
  setComments,
  onTimeStampPress,
}) => {
  const [searchParams] = useSearchParams();
  const applicationId = Number(searchParams.get("application_id") || 0);
  const [text, setText] = useState<string>("");
  const [editIndex, setEditIndex] = useState<null | number>(null);
  const [editText, setEditText] = useState("");
  const bottomRef = useRef<HTMLDivElement>(null);

  // Load comments from local storage on component mount
  useEffect(() => {
    const storedComments = localStorage.getItem(`application-${applicationId}`);
    if (storedComments) {
      setComments(JSON.parse(storedComments));
    }
  }, [applicationId, setComments]);

  const handleSubmit = () => {
    if (!text.trim()) return;

    const newComment: Comment = {
      parent_comment: {
        text,
        created_at: new Date().toISOString(),
        video_timestamp: videoCommentTimestamp,
      },
    };

    // Get existing comments for the current applicationId, or start with an empty array
    const storedComments = JSON.parse(
      localStorage.getItem(`application-${applicationId}`) || "[]"
    );

    // Add the new comment to the list
    const updatedComments = [...storedComments, newComment];

    // Save the updated comments for the current applicationId
    localStorage.setItem(
      `application-${applicationId}`,
      JSON.stringify(updatedComments)
    );

    // Update state to re-render
    setComments(updatedComments);

    // Clear the input
    setText("");

    // Scroll to the bottom
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  // Function to delete a comment from local storage
  const deleteComment = (index: number) => {
    // Remove the comment at the specified index
    const updatedComments = comments.filter((_, i) => i !== index);

    // Save the updated comments to local storage
    localStorage.setItem(
      `application-${applicationId}`,
      JSON.stringify(updatedComments)
    );

    // Update state to reflect the changes
    setComments(updatedComments);
  };

  const editComment = (index: number, comment: string) => {
    setEditIndex(index);
    setEditText(comment);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 70px)",
        minHeight: 0,
      }}
    >
      <div
        style={{
          overflow: "auto",
          padding: "8px 12px",
          flex: 1,
        }}
      >
        {comments.length === 0 ? (
          <div>No comments added yet.</div>
        ) : (
          comments.map((comment, index) => (
            <div
              style={{
                padding: "12px",
                backgroundColor: COLORS.WHITE,
                border: `1px solid ${COLORS.GREY50}`,
                borderRadius: "6px",
                marginBottom: "11px",
                display: "flex",
                gap: "8px",
              }}
              key={index}
            >
              <div
                style={{
                  height: "32px",
                  width: "32px",
                  borderRadius: "100%",
                  flexShrink: 0,
                  backgroundColor: COLORS.GREY400,
                }}
              ></div>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "12px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "12px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "20px",
                      }}
                    >
                      You
                    </p>
                    <p
                      style={{
                        backgroundColor: COLORS.PURPLE100,
                        padding: "4px",
                        color: COLORS.PRIMARY,
                        fontSize: "12px",
                        fontWeight: "600",
                        lineHeight: "16px",
                        cursor: "pointer",
                        borderRadius: "4px",
                      }}
                      onClick={() => {
                        onTimeStampPress(
                          comment.parent_comment.video_timestamp
                        );
                      }}
                    >
                      {dateTime.dateTime.formatSecondsToTime(
                        comment.parent_comment.video_timestamp
                      )}
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "16px",
                        color: COLORS.GREY400,
                      }}
                    >
                      {timeAgo(comment.parent_comment.created_at)}
                    </p>
                  </div>
                  <FloatingOptions
                    onDelete={() => deleteComment(index)}
                    onEdit={() =>
                      editComment(index, comment.parent_comment.text)
                    }
                  />
                </div>

                {editIndex === index ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifySelf: "flex-end",
                      boxShadow: "4px 4px 30px 0px #0000001a",
                      borderRadius: "4px",
                      overflow: "hidden",
                    }}
                  >
                    <StyledCommentEditInput
                      autoFocus
                      style={{}}
                      value={editText}
                      onChange={(e) => setEditText(e.target.value)}
                      placeholder="Edit your comment here"
                      onFocus={handleFeedbackFocus}
                    />
                    <div
                      style={{
                        backgroundColor: COLORS.WHITE,
                        padding: "8px 12px",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "12px",
                      }}
                    >
                      <Button
                        text="Discard"
                        onClick={() => {
                          setEditIndex(null);
                        }}
                        style={{ minWidth: "100px", height: "40px" }}
                      />
                      <Button
                        type="primary"
                        text="Save"
                        onClick={() => {
                          if (editText.trim()) {
                            // Create a copy of the current comments
                            const updatedComments = [...comments];

                            // Update the comment at the specified index
                            updatedComments[editIndex!] = {
                              ...updatedComments[editIndex!],
                              parent_comment: {
                                ...updatedComments[editIndex!].parent_comment,
                                text: editText, // Update the text
                                video_timestamp: videoCommentTimestamp,
                              },
                            };

                            // Save the updated comments to local storage
                            localStorage.setItem(
                              `application-${applicationId}`,
                              JSON.stringify(updatedComments)
                            );

                            // Update state to re-render
                            setComments(updatedComments);

                            // Clear the edit state
                            setEditIndex(null);
                            setEditText("");
                          }
                        }}
                        style={{ minWidth: "100px", height: "40px" }}
                      />
                    </div>
                  </div>
                ) : (
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "16px",
                      color: COLORS.GREY500,
                      wordBreak: "break-word",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                      display: "block",
                      maxWidth: "100%",
                      wordWrap: "break-word",
                    }}
                  >
                    {renderMessageWithLinks(comment.parent_comment.text)}
                  </p>
                )}
              </div>
            </div>
          ))
        )}
        <div ref={bottomRef} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifySelf: "flex-end",
        }}
      >
        <StyledCommentInput
          style={{}}
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Add your comment here"
          onFocus={handleFeedbackFocus}
        />
        <div
          style={{
            backgroundColor: COLORS.WHITE,
            padding: "8px 12px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            style={{
              cursor: "pointer",
              marginLeft: "auto",
              padding: "12px 16px",
              backgroundColor: COLORS.WHITE,
              border: `1px solid ${COLORS.GREY400}`,
              borderRadius: "50px",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "600",
            }}
            onClick={handleSubmit}
          >
            Comment at{" "}
            {dateTime.dateTime.formatSecondsToTime(videoCommentTimestamp)}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommentInput;

export const SubmissionPostVerified: React.FC<types.SubmissionPostedProps> = ({
  submission,
  partner,
}) => {
  const videoRef = useRef<null | HTMLVideoElement>(null);
  const [searchParams] = useSearchParams();
  const applicationId = Number(searchParams.get("application_id") || 0);
  const navigation = useNavigate();
  const { campaignId } = useParams();
  const [showInfluencerDetails, setShowInfluencerDetails] = useState(false);

  useEffect(() => {
    const hls = new Hls({
      debug: true,
    });

    if (Hls.isSupported() && videoRef.current) {
      hls.loadSource(submission.content.url);
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.ERROR, (err) => {
        console.log(err);
      });
    } else {
      console.log("load");
    }
    return () => {
      // cleanup (when component destroyed or when useEffect runs twice on StrictMode)
      hls.destroy();
    };
  }, [submission.content.url]);

  if (!applicationId) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: COLORS.WHITE,
        borderRadius: "8px",
        padding: "12px 18px",
      }}
    >
      <ApplicantProfileContext
        type="submission"
        modal
        showModal={showInfluencerDetails}
        application_id_prop={applicationId}
        onClose={() => setShowInfluencerDetails(false)}
      />

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "5fr 5fr",
          gap: "12px",
          height: "calc(100vh - 50px - 70px - 50px - 6px)",
        }}
      >
        {/* LEFT */}
        <div
          style={{
            flex: 6,
            backgroundColor: COLORS.GREY50,
            borderRadius: "8px",
            gap: "12px",
            height: "100%",
            width: "100%",
            padding: "12px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginBottom: "12px",
            }}
          >
            <p style={{ fontWeight: "700" }}>Live on Instagram</p>
            {submission.brand_visible_status ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Tag color={CONSTANTS.COLOR_MAP[submission.status]}>
                  {submission.brand_visible_status}
                </Tag>
              </div>
            ) : null}
          </div>

          <video
            style={{
              width: "100%",
              height: "calc(100% - 88px)",
              objectFit: "contain",
              backgroundColor: "#000",
              borderRadius: "4px",
            }}
            controls
            ref={videoRef}
          />
          <div
            style={{
              padding: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              text="Check post on Instagram"
              iconPosition="end"
              type="primary"
              style={{ height: "40px", display: "flex", alignItems: "center" }}
              icon={
                <ContainarizedImage
                  src={ICONS.link}
                  height="20px"
                  width="20px"
                  alt="link"
                />
              }
              onClick={() => {
                window.open(submission.post_url, "_blank");
              }}
            />
          </div>
        </div>
        {/* RIGHT */}
        <div
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "8px",
            padding: "14px 24px",
            paddingRight: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "5rem",
            }}
          >
            <InlfuencerDetailButton
              setShowInfluencerDetails={() => {
                setShowInfluencerDetails(true);
              }}
              name={partner.name}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "left",
            }}
          >
            <ContainarizedImage
              alt="success"
              src={SuccessIllustration}
              width="75px"
              height="75px"
              contStyle={{
                marginBottom: "28px",
              }}
            />
            <div style={{ textAlign: "center" }}>
              <p
                style={{
                  fontSize: "24px",
                  lineHeight: "30px",
                  fontWeight: "600",
                  marginBottom: "4px",
                }}
              >
                Post Approved
              </p>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: COLORS.GREY500,
                  marginBottom: "28px",
                }}
              >
                You have approved the post. You can now view the submission
                insights and monitor its performance on Instagram.
              </p>
              <Button
                onClick={() => {
                  navigation(`/brand/campaigns/${campaignId}/insights`);
                }}
                text="View Submission Insights"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StyledCommentInput = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 0;
  border-top: 1px solid ${COLORS.GREY200};
  border-bottom: 1px solid ${COLORS.GREY200};
  outline: none;
  resize: none;

  /* Placeholder styling */
  ::placeholder {
    color: ${COLORS.GREY400};
    opacity: 1; /* Ensures full opacity in Firefox */
  }

  /* For older versions of Firefox */
  ::-moz-placeholder {
    color: ${COLORS.GREY400};
    opacity: 1;
  }

  /* For Internet Explorer */
  ::-ms-input-placeholder {
    color: ${COLORS.GREY400};
  }

  /* For WebKit browsers (Chrome, Safari) */
  ::-webkit-input-placeholder {
    color: ${COLORS.GREY400};
  }
`;

const StyledCommentEditInput = styled(StyledCommentInput)`
  border: 1px solid ${COLORS.GREY100};
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;
